<template>
  <div>
    <v-tabs fixed-tabs background-color="primary" dark>
      <v-tab>
        {{ $t("alarm.tabs.triggered") }}
      </v-tab>
      <v-tab>
        {{ $t("alarm.tabs.allAlarms") }}
      </v-tab>
      <v-tab>
        {{ $t("alarm.tabs.history") }}
      </v-tab>

      <v-tab-item>
        <v-data-table
          :headers="headers"
          :items="triggeredAlarms"
          :loading="alarmStatus.loading"
          :items-per-page="50"
          class="elevation-1"
        >
          <!-- Status -->
          <template v-slot:[`item.statusColor`]="{ item }">
            <v-avatar
              class="rounded-box"
              :color="getStatusColor(item).color"
              size="30"
            >
              <v-icon
                :class="{
                  'status-icon': true,
                  blinking: getStatusColor(item).blinking,
                }"
                :style="{
                  backgroundColor: getStatusColor(item).color,
                  fontSize: '20px',
                }"
              >
                {{ getStatusColor(item).icon }}
              </v-icon>
            </v-avatar>
          </template>

          <!-- Priority -->
          <template v-slot:[`item.priority`]="{ item }">
            <v-icon :color="getPriorityChar(item.priority).color" :size="34">
              {{ getPriorityChar(item.priority).icon }}
            </v-icon>
          </template>

          <!-- Latest Reset Time -->
          <template v-slot:[`item.lastAcknowledged`]="{ item }">
            {{ getLatestResetTime(item.alarmId) }}
          </template>

          <!-- Node -->
          <template v-slot:[`item.tagName`]="{ item }">
            <span>
              {{ item.tag?.name }}
              <span v-if="item.advanced" style="font-style: italic">{{
                $t("common.various")
              }}</span>
            </span>
          </template>

          <!-- Key -->
          <template v-slot:[`item.label`]="{ item }">
            <span>
              {{ item.label }}
              <span v-if="item.advanced" style="font-style: italic">{{
                $t("common.various")
              }}</span>
            </span>
          </template>

          <!-- Latest Value -->
          <template v-slot:[`item.latestValue`]="{ item }">
            <span>
              {{ item.latestValue }}
              <span v-if="item.advanced" style="font-style: italic">{{
                $t("common.various")
              }}</span>
            </span>
          </template>

          <!-- Low Threshold -->
          <template v-slot:header.limitLow="{ header }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ header.text }}</span>
              </template>
              <span>{{ $t("alarm.fields.limitLow") }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.limitLow="{ item }">
            {{ item.limitLow != null ? item.limitLow : "N/A" }}
          </template>

          <!-- High Threshold -->
          <template v-slot:header.limitHigh="{ header }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ header.text }}</span>
              </template>
              <span>{{ $t("alarm.fields.limitHigh") }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.limitHigh="{ item }">
            {{ item.limitHigh != null ? item.limitHigh : "N/A" }}
          </template>

          <!-- ? Adavanced -->
          <template v-slot:[`item.advanced`]="{ item }">
            <v-icon :color="item.advanced ? 'success' : 'error'" size="30">{{
              item.advanced ? "mdi mdi-check-circle" : "mdi mdi-alpha-x-circle"
            }}</v-icon>
          </template>

          <!-- ? Active -->
          <template v-slot:[`item.active`]="{ item }">
            <v-icon :color="item.active ? 'success' : 'error'" size="30">
              {{ item.active ? "mdi-check-circle" : "mdi-alpha-x-circle" }}
            </v-icon>
          </template>

          <!-- ? Auto Acknowledge -->
          <template v-slot:[`item.autoAcknowledge`]="{ item }">
            <v-icon
              :color="item.autoAcknowledge ? 'success' : 'error'"
              size="30"
              >{{
                item.autoAcknowledge
                  ? "mdi mdi-check-circle"
                  : "mdi mdi-alpha-x-circle"
              }}</v-icon
            >
          </template>

          <!-- Warning -->
          <template v-slot:[`item.activeAlarms.length`]="{ item }">
            <div v-if="item.activeAlarms.length > 0">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-badge
                      :color="
                        item.activeAlarms.length > 0 ? 'error' : 'success'
                      "
                      overlap
                      bordered
                    >
                      <template v-slot:badge>
                        {{ item.activeAlarms.length }}
                      </template>
                      <v-avatar
                        :size="item.hovered ? 40 : 30"
                        @mouseover="setHovered(item, true)"
                        @mouseleave="setHovered(item, false)"
                        :color="
                          item.activeAlarms.length > 0 ? 'error' : 'success'
                        "
                      >
                        <v-icon
                          @click="openAlarmActiveDialog(item)"
                          size="20"
                          color="white"
                        >
                          mdi-alarm-light
                        </v-icon>
                      </v-avatar>
                    </v-badge>
                  </div>
                </template>
                <span>{{ $t("alarm.acknowledgeButton") }}</span>
              </v-tooltip>
            </div>
            <div v-else>
              <v-badge color="success" overlap bordered>
                <template v-slot:badge>
                  {{ item.activeAlarms.length }}
                </template>
                <v-avatar
                  @click="openAlarmActiveDialog(item)"
                  size="30"
                  color="success"
                >
                  <v-icon size="20" color="white"> mdi-alarm-light </v-icon>
                </v-avatar>
              </v-badge>
            </div>
          </template>
        </v-data-table>
      </v-tab-item>

      <v-tab-item>
        <v-data-table
          :headers="headers"
          :items="sortedAlarms"
          :loading="alarmStatus.loading"
          :items-per-page="50"
          class="elevation-1"
        >
          <template v-slot:[`item.statusColor`]="{ item }">
            <v-avatar
              class="rounded-box"
              :color="getStatusColor(item).color"
              size="30"
            >
              <v-icon
                :class="{
                  'status-icon': true,
                  blinking: getStatusColor(item).blinking,
                }"
                :style="{
                  backgroundColor: getStatusColor(item).color,
                  fontSize: '20px',
                }"
              >
                {{ getStatusColor(item).icon }}
              </v-icon>
            </v-avatar>
          </template>

          <template v-slot:[`item.priority`]="{ item }">
            <v-icon :color="getPriorityChar(item.priority).color" :size="34">
              {{ getPriorityChar(item.priority).icon }}
            </v-icon>
          </template>

          <template v-slot:[`item.lastAcknowledged`]="{ item }">
            {{ getLatestResetTime(item.alarmId) }}
          </template>

          <template v-slot:[`item.tagName`]="{ item }">
            <span>
              {{ item.tag?.name }}
              <span v-if="item.advanced" style="font-style: italic">{{
                $t("common.various")
              }}</span>
            </span>
          </template>

          <template v-slot:[`item.label`]="{ item }">
            <span>
              {{ item.label }}
              <span v-if="item.advanced" style="font-style: italic">{{
                $t("common.various")
              }}</span>
            </span>
          </template>

          <template v-slot:[`item.latestValue`]="{ item }">
            <span>
              {{ item.latestValue }}
              <span v-if="item.advanced" style="font-style: italic">{{
                $t("common.various")
              }}</span>
            </span>
          </template>

          <template v-slot:header.limitLow="{ header }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ header.text }}</span>
              </template>
              <span>{{ $t("alarm.fields.limitLow") }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.limitLow="{ item }">
            {{ item.limitLow != null ? item.limitLow : "N/A" }}
          </template>

          <template v-slot:header.limitHigh="{ header }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ header.text }}</span>
              </template>
              <span>{{ $t("alarm.fields.limitHigh") }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.limitHigh="{ item }">
            {{ item.limitHigh != null ? item.limitHigh : "N/A" }}
          </template>

          <template v-slot:[`item.advanced`]="{ item }">
            <v-icon :color="item.advanced ? 'success' : 'error'" size="30">{{
              item.advanced ? "mdi mdi-check-circle" : "mdi mdi-alpha-x-circle"
            }}</v-icon>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-icon :color="item.active ? 'success' : 'error'" size="30">
              {{ item.active ? "mdi-check-circle" : "mdi-alpha-x-circle" }}
            </v-icon>
          </template>

          <template v-slot:[`item.autoAcknowledge`]="{ item }">
            <v-icon
              :color="item.autoAcknowledge ? 'success' : 'error'"
              size="30"
              >{{
                item.autoAcknowledge
                  ? "mdi mdi-check-circle"
                  : "mdi mdi-alpha-x-circle"
              }}</v-icon
            >
          </template>

          <template v-slot:[`item.activeAlarms.length`]="{ item }">
            <div v-if="item.activeAlarms.length > 0">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-badge
                      :color="
                        item.activeAlarms.length > 0 ? 'error' : 'success'
                      "
                      overlap
                      bordered
                    >
                      <template v-slot:badge>
                        {{ item.activeAlarms.length }}
                      </template>
                      <v-avatar
                        :size="item.hovered ? 40 : 30"
                        @mouseover="setHovered(item, true)"
                        @mouseleave="setHovered(item, false)"
                        :color="
                          item.activeAlarms.length > 0 ? 'error' : 'success'
                        "
                      >
                        <v-icon
                          @click="openAlarmActiveDialog(item)"
                          size="20"
                          color="white"
                        >
                          mdi-alarm-light
                        </v-icon>
                      </v-avatar>
                    </v-badge>
                  </div>
                </template>
                <span>{{ $t("alarm.acknowledgeButton") }}</span>
              </v-tooltip>
            </div>
            <div v-else>
              <v-badge color="success" overlap bordered>
                <template v-slot:badge>
                  {{ item.activeAlarms.length }}
                </template>
                <v-avatar
                  @click="openAlarmActiveDialog(item)"
                  size="30"
                  color="success"
                >
                  <v-icon size="20" color="white"> mdi-alarm-light </v-icon>
                </v-avatar>
              </v-badge>
            </div>
          </template>

          <template v-slot:[`item.edit`]="{ item }">
            <v-icon @click.stop="clickedRow(item)" color="blue"
              >mdi-pencil</v-icon
            >
          </template>
        </v-data-table>
      </v-tab-item>

      <v-tab-item>
        <v-data-table
          :items="alarmActives"
          :headers="historyHeaders"
          sort-by="updatedAt"
          :sort-desc="true"
          class="logtable"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ humanDate(item.createdAt, "dd MMM - HH:mm:ss") }}
          </template>

          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ humanDate(item.updatedAt, "dd MMM - HH:mm:ss") }}
          </template>

          <template v-slot:[`item.user.email`]="{ item }">
            {{ resetBy(item) }}
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>

    <v-dialog v-model="showDialog" max-width="500px">
      <v-card>
        <alarm-active
          ref="alarmActive"
          :selected-item="selectedAlarm"
          @confirmed="onAlarmConfirmed"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">
            {{ $t("common.cancel") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="confirmAlarm">
            {{ $t("alarm.affirmation") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Roles } from "@/_helpers/Role";
import AlarmActive from "@/components/alarm/ActiveAlarms.vue";
import i18n from "../../plugins/i18n";

export default {
  name: "AlarmList",

  components: {
    AlarmActive,
  },

  data() {
    return {
      alarms: [],
      history: false,
      filteredActives: [],
      keyLabels: [],
      headers: [
        { text: this.$t("alarm.fields.name"), value: "name" },
        { text: "Status", value: "statusColor" },
        { text: this.$t("alarm.fields.priority"), value: "priority" },
        { text: this.$t("alarm.fields.reset"), value: "lastAcknowledged" },
        { text: this.$t("alarm.fields.tag"), value: "tagName" },
        { text: this.$t("alarm.fields.tagDataKey"), value: "label" },
        {
          text: this.$t("alarm.fields.lL"),
          value: "limitLow",
        },
        { text: this.$t("tag.fields.latestValue"), value: "latestValue" },
        { text: this.$t("alarm.fields.lH"), value: "limitHigh" },
        { text: this.$t("alarm.fields.advanced"), value: "advanced" },
        { text: this.$t("alarm.fields.active"), value: "active" },
        {
          text: this.$t("alarm.fields.autoAcknowledge"),
          value: "autoAcknowledge",
        },
        {
          text: this.$t("alarm.fields.activeAlarms"),
          value: "activeAlarms.length",
        },
        { text: "", value: "edit", sortable: false },
      ],
      historyHeaders: [
        { text: this.$t("alarm.fields.triggered"), value: "createdAt" },
        { text: this.$t("alarm.fields.reset"), value: "updatedAt" },
        { text: this.$t("alarm.fields.name"), value: "alarm.name" },
        { text: this.$t("alarm.fields.message"), value: "alarm.message" },
        { text: this.$t("alarm.fields.alarmComment"), value: "alarmComment" },
        { text: this.$t("alarm.fields.user"), value: "user.email" },
        { text: this.$t("alarm.fields.value"), value: "value" },
      ],
      showDialog: false,
      selectedAlarm: null,
      hovered: false,
    };
  },
  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),
    ...mapState("alarms", {
      alarmStatus: "status",
      storeAlarms: "alarms",
      alarmActives: "activeAlarms",
    }),
    ...mapState("tagData", ["currentTagData"]),

    Roles() {
      return Roles;
    },

    sortedAlarms() {
      const colorPriority = {
        error: 1,
        warning: 2,
        success: 3,
        lightgrey: 4,
      };

      return this.alarms.slice().sort((a, b) => {
        const statusA = this.getStatusColor(a);
        const statusB = this.getStatusColor(b);

        const colorA = colorPriority[statusA.color];
        const colorB = colorPriority[statusB.color];

        return colorA - colorB;
      });
    },

    triggeredAlarms() {
      return this.alarms
        .filter((alarm) => alarm.active)
        .filter((alarm) => {
          const isAlarmActive = alarm.activeAlarms.length > 0;
          return isAlarmActive || alarm.flagging;
        })
        .sort((a, b) => {
          return b.activeAlarms.length - a.activeAlarms.length;
        });
    },
  },

  methods: {
    ...mapActions("alarms", [
      "getAlarms",
      "setCurrentAlarm",
      "getAlarmHistory",
      "setAlarmStatus",
    ]),
    ...mapActions("tagData", { getTagData: "getCurrentTagdata" }),

    setHovered(item, isHovered) {
      this.$set(item, "hovered", isHovered);
    },

    openAlarmActiveDialog(item) {
      if (item.activeAlarms && item.activeAlarms.length > 0) {
        this.selectedAlarm = {
          alarmActiveId: item.activeAlarms[0].alarmActiveId,
          alarm: {
            name: item.name,
            message: item.message,
          },
          messageSent: item.activeAlarms[0].messageSent,
        };
        this.showDialog = true;
      }
    },

    async confirmAlarm() {
      if (this.$refs.alarmActive) {
        await this.$refs.alarmActive.confirmAlarm();
      }
    },

    async onAlarmConfirmed() {
      this.showDialog = false;
      await this.getAlarms();
      this.alarms = this.storeAlarms.slice();
      await this.addLatestValues();
      await this.getAlarmHistory();
    },

    closeDialog() {
      this.showDialog = false;
      this.$refs.alarmActive.reset();
    },

    resetBy(item) {
      if (item.alarm && item.user == null) {
        return i18n.t("common.system");
      }
      return item.user && item.user.email ? item.user.email : "N/A";
    },

    getStatusColor(item) {
      let icon,
        color,
        blinking = false;

      if (!item.active) {
        icon = "mdi-alarm-note-off";
        color = "lightgrey";
      } else if (item.activeAlarms.length > 0) {
        icon = "mdi-alert-outline";
        color = "error";
        blinking = true;
      } else if (
        item.flagging
      ) {
        icon = "mdi-alert-outline";
        color = "warning";
      } else {
        icon = "mdi-check-circle-outline";
        color = "success";
      }

      return { icon, color, blinking };
    },

    getPriorityChar(val) {
      switch (val) {
        case 0:
          return { icon: "mdi-speedometer", color: "error" };
        case 1:
          return { icon: "mdi-speedometer-medium", color: "warning" };
        case 2:
          return { icon: "mdi-speedometer-slow", color: "success" };
        default:
          return "N/A";
      }
    },

    async addLatestValues() {
      for (let a = 0; a < this.alarms.length; a++) {
        if (this.alarms[a].advanced == false) {
          await this.getTagData({
            tagId: this.alarms[a].tag.deveui,
            tagKey: this.alarms[a].tagDataKey,
          });

          // We need to use set function to maintain reactivity
          let tmpAlarm = this.alarms[a];
          const value = parseFloat(this.currentTagData.value);
          tmpAlarm.latestValue = Number.isInteger(value)
            ? value
            : value.toFixed(2);
          this.$set(this.alarms, a, tmpAlarm);
        }
      }
    },

    getLatestResetTime(alarmId) {
      const relevantActivesForAlarm = this.alarmActives.filter(
        (active) => active.alarm.alarmId === alarmId
      );
      if (relevantActivesForAlarm.length > 0) {
        const latestActive = relevantActivesForAlarm.reduce((latest, current) =>
          new Date(current.updatedAt) > new Date(latest.updatedAt)
            ? current
            : latest
        );
        return this.humanDate(latestActive.updatedAt, "dd MMM - HH:mm:ss");
      }
      return "N/A";
    },

    async clickedRow(item) {
      await this.setCurrentAlarm(item);

      if (this.permitted("Alarm.Update"))
        this.$router.push(`/alarm/${item.alarmId}`);
    },
  },

  async created() {
    await this.getAlarms();
    this.alarms = this.storeAlarms.slice();
    await this.addLatestValues();
    await this.getAlarmHistory();
  },
};
</script>

<style scoped>
.rounded-box {
  border-radius: 14px;
}
.status-icon {
  color: white;
  size: 50%;
}
.blinking {
  animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>
